import React, { useEffect, useState } from 'react'
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

import {
    Grid,
    Link,
    Box,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    TextField,
    DialogContentText,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Divider

} from "@mui/material";

import Topic from '../components/Topic';
import axios from 'axios';
import config from '../config';
import Course from '../components/Course';
import Content from '../components/Content';


export default function Home({ user }) {

    const [fetchAgain, setFetchAgain] = useState("ok");

    const [topics, setTopics] = useState([]);
    const [openTopic, setOpenTopic] = useState(false);

    // for create topic
    const [topicName, setTopicName] = useState("");
    const [topicDescription, setTopicDescription] = useState("");

    const getTopics = () => {
        axios.get(config.BASE_URL + "topics", {
            headers: {
                Authorization: "Bearer " + user.token
            }
        }).then(response => {
            let data = response.data;

            setTopics(data);
        })
    }

    const createTopic = () => {
        // make http request
        axios.post(config.BASE_URL + "topics", {
            name: topicName,
            description: topicDescription
        }, {
            headers: {
                Authorization: "Bearer " + user.token
            }
        }).then(response => {
            let data = response.data;

            setOpenTopic(false);
            setFetchAgain(fetchAgain + "k");
        })
    }

    const [courses, setCourses] = useState([]);
    const [openCourse, setOpenCourse] = useState(false);

    // for create course
    const [courseTitle, setCourseTitle] = useState("");
    const [courseDescription, setCourseDescription] = useState("");
    const [courseTopicId, setCourseTopicId] = useState(0);

    const getCourses = () => {
        axios.get(config.BASE_URL + "course", {
            headers: {
                Authorization: "Bearer " + user.token
            }
        }).then(response => {
            let data = response.data;

            setCourses(data);
        })
    }

    const createCourse = () => {
        // make http request
        axios.post(config.BASE_URL + "course", {
            title: courseTitle,
            description: courseDescription,
            topicId: courseTopicId
        }, {
            headers: {
                Authorization: "Bearer " + user.token
            }
        }).then(response => {
            let data = response.data;

            setOpenCourse(false);
            setFetchAgain(fetchAgain + "k");
        })
    }

    const [contents, setContents] = useState([]);
    const [openContent, setOpenContent] = useState(false);

    // for create course
    const [contentTitle, setContentTitle] = useState("");
    const [contentDescription, setContentDescription] = useState("");
    const [contentURL, setContentURL] = useState("");
    const [contentType, setContentType] = useState(0);

    const getContents = () => {
        axios.get(config.BASE_URL + "content", {
            headers: {
                Authorization: "Bearer " + user.token
            }
        }).then(response => {
            let data = response.data;

            setContents(data);
        })
    }

    const createContent = () => {
        // make http request
        axios.post(config.BASE_URL + "content", {
            title: contentTitle,
            description: contentDescription,
            link: contentURL,
            contentType: contentType
        }, {
            headers: {
                Authorization: "Bearer " + user.token
            }
        }).then(response => {
            let data = response.data;

            setOpenContent(false);
            setFetchAgain(fetchAgain + "k");
        })
    }

    useEffect(() => {
        getTopics();
        getCourses();
        getContents();
    }, [fetchAgain])

    const DrawerHeader = styled("div")(({ theme }) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));

    return (
        <React.Fragment>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <DrawerHeader />
                <Grid container>
                    <Grid item md>
                        <br />
                        <Divider />
                        <Topic topics={topics} setOpenTopic={setOpenTopic} />
                    </Grid>
                    <Grid item md>
                        <br />
                        <Divider />
                        <Content contents={contents} setOpenContent={setOpenContent} />
                    </Grid>

                    <Grid item md>
                        <br />
                        <Divider />
                        <Course courses={courses} setOpenCourse={setOpenCourse} />
                    </Grid>
                </Grid>
            </Box>

            {/* Topic Dialog */}
            <Dialog open={openTopic} onClose={() => { setOpenTopic(false) }}>
                <DialogTitle>Create Topic</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Create a Topic
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Topic Name"
                        fullWidth
                        variant="standard"
                        onChange={(e) => setTopicName(e.target.value)}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="description"
                        label="Topic Description"
                        fullWidth
                        variant="standard"
                        multiline
                        rows={4}
                        onChange={(e) => setTopicDescription(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpenTopic(false) }}>Cancel</Button>
                    <Button onClick={createTopic}>Create Topic</Button>
                </DialogActions>
            </Dialog>

            {/* Course Dialog */}
            <Dialog open={openCourse} onClose={() => { setOpenCourse(false) }}>
                <DialogTitle>Create Course</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Create a Course
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Course Title"
                        fullWidth
                        variant="standard"
                        onChange={(e) => setCourseTitle(e.target.value)}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="description"
                        label="Course Description"
                        fullWidth
                        variant="standard"
                        multiline
                        rows={4}
                        onChange={(e) => setCourseDescription(e.target.value)}
                    />
                    <FormControl variant="standard" fullWidth>
                        <InputLabel id="demo-select-small">Course Topic Id</InputLabel>
                        <Select
                            labelId="demo-select-small"
                            onChange={(e) => setCourseTopicId(e.target.value)}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {topics.map(item => {
                                return (
                                    <MenuItem key={item._id} value={item._id}>{item.name}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpenCourse(false) }}>Cancel</Button>
                    <Button onClick={createCourse}>Create Course</Button>
                </DialogActions>
            </Dialog>

            {/* Content Dialog */}
            <Dialog open={openContent} onClose={() => { setOpenContent(false) }}>
                <DialogTitle>Create Content</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Create a Content
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Content Title"
                        fullWidth
                        variant="standard"
                        onChange={(e) => setContentTitle(e.target.value)}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="description"
                        label="Content Description"
                        fullWidth
                        variant="standard"
                        multiline
                        rows={4}
                        onChange={(e) => setContentDescription(e.target.value)}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="url"
                        label="Content URL"
                        fullWidth
                        variant="standard"
                        multiline
                        rows={4}
                        onChange={(e) => setContentURL(e.target.value)}
                    />
                    <FormControl variant="standard" fullWidth>
                        <InputLabel id="demo-select-small">Content Type</InputLabel>
                        <Select
                            labelId="demo-select-small"
                            onChange={(e) => setContentType(e.target.value)}
                        >
                            <MenuItem value="VIDEO">Video</MenuItem>
                            <MenuItem value="BLOG">Blog</MenuItem>
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpenContent(false) }}>Cancel</Button>
                    <Button onClick={createContent}>Create Content</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
