
import React, {useState} from "react";
import {
  BrowserRouter as Router,
  useRoutes,
} from "react-router-dom";
import  Login from './pages/Login'
import  Logout from './pages/Logout'
import Box from "@mui/material/Box";
import Home from "./pages/Home"
import MiniDrawer from './components/Sidebar'
import Course from "./pages/Course";



const App = (props) => { 
  let routes = useRoutes([
    { path: "/", element: props.user ? <Home user={props.user} />: <Login setUser={props.setUser} /> },
    { path: "/home", element: props.user ? <Home  />: <Login setUser={props.setUser} /> },
    { path: "/login", element: <Login setUser={props.setUser} /> },
    { path: "/logout", element: <Login setUser={props.setUser} /> },
    { path: "/course/:courseSlug", element: props.user ? <Course user={props.user} />:  <Login setUser={props.setUser} /> },
  ]);
  return routes;
}

const AppWrapper = () => {
  const [user, setUser] = useState(null);


  let sidebar;
  if(user) {
    sidebar = <MiniDrawer user={user} setUser={setUser} />
  }
  
  return (
    <Router>
      <Box sx={{ display: "flex" }}>
        {sidebar}
        <App user={user} setUser={setUser}  />
      </Box>      
    </Router>
  );
};

export default AppWrapper;
